<template>
  <div>
    <div class="flex justify-end">
      <div class="flex items-center mx-4">
        <p class="text-card text-md font-semibold pr-2">Filter by</p>
        <el-select v-model="repaymentPlan" placeholder="Select filter option" @change="filterEvent" clearable>
          <el-option v-for="item in repayment_plans" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="mr-4">
        <el-input placeholder="Search" v-model="searchQuery" @input="search"></el-input>
      </div>
    </div>
    <div class="mt-10">
      <el-table :data="loans.allUpcomingRepayments" v-loader="loans.loader" style="width:100%;">
        <el-table-column label="Due Date">
          <template slot-scope="scope">
            <span>{{ scope.row.due_at | formatDate }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="loan_request_id" label="Loan ID"></el-table-column>
        <el-table-column label="Amount Due">
          <template slot-scope="scope">
            <span> {{ scope.row.amount_requested | currencyFormat}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="customer_name" label="Customer"></el-table-column>

      </el-table>
      <div class="mt-10" v-if="loans.allUpcomingRepayments.length > 0">
        <el-pagination background @size-change="upcomingRepaymentsHandleSizeChange" @current-change="upcomingRepaymentsHandleCurrentChange"
          style="float: right;" :current-page.sync="currentPage" :page-sizes="pageSizes" :page-size="loans.perPage"
          layout="total, sizes, prev, pager, next, jumper" :total="loans.upcomingRepaymentsTotal">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import debounce from 'lodash.debounce';

export default {
  data() {
    return {
      pageSizes: this.$store.state.pageSizes,
      value: '',
      input: '',
      repayment_plans: [
        {
          value: 'weekly',
          label: 'Weekly'
        }, {
          value: 'monthly',
          label: 'Monthly'
        }
      ],
    }
  },
  mounted() {
    this.getAllUpcomingRepayments()
  },
  computed: {
    ...mapState(['loans']),
    currentPage: {
      get() {
        return this.loans.currentPage
      },
      set(value) {
        return this.$store.commit('mutate', {
          property: 'currentPage',
          with: value
        })
      },
      searchQuery: {
        get() {
          return this.loan.searchQuery
        },
        set(value) {
          return this.$store.commit('mutate', {
            property: 'searchQuery',
            with: value
          })
        }
      },
      repaymentPlan: {
        get() {
          return this.loan.repayment_plan
        },
        set(value) {
          return this.$store.commit('mutate', {
            property: 'repayment_plan',
            with: value
          })
        }
      }
    }
  },
  methods: {
    ...mapActions([
      'upcomingRepaymentsHandleSizeChange',
      'upcomingRepaymentsHandleCurrentChange',
      'getAllUpcomingRepayments',
    ]),
    filterEvent() {
      this.$store.commit('mutate', {
        property: 'currentPage',
        with: 1
      })
      this.getAllUpcomingRepayments()
    },
    search: debounce(function () {
      this.getAllUpcomingRepayments()
    }, 500)
  }
}
</script>

<style>
.el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #11141A;
  color: #FFF;
}

.el-table th>.cell {
  color: #11141A;
}
</style>